/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 23.11.2017, 10:45:00
    Author     : linkus
*/

$colorOrange:#ec6b10;
$colorDarkGrey:#191a1c;
$colorLightGrey:#222426;
$colorLightLightGrey:#2f3234;

@import "functions";
@import "bootstrap/bootstrap-custom";
@import "animation";

@import "../../node_modules/slick-carousel/slick/slick.scss";

.row-eq-height {
    display: flex;
    flex-direction: column;
    &.bottom {
        align-items:flex-end;
    }
    > * {
        // flex: 1 0 auto;
        overflow: hidden;
    }
    @media(min-width: 992px) {
        flex-direction: row;
        .home & {
            > .grid-4 {
                flex-basis: 33.333%;
            }
        }
    }
}

.flex-direction {
    flex-direction:row-reverse;
    -webkit-flex-direction:row-reverse;
    -moz-flex-direction:row-reverse;
}

.justify-content {
    justify-content:flex-end;
    -webkit-justify-content:flex-end;
    -mox-justify-content:flex-end;
}

body {
    font-family:'Gudea',sans-serif,Helvetica Neue,Helvetica,Arial;
    font-size:16px;
    overflow-x:hidden;

    img, object { display:block; max-width:100%; height:auto; }

    .hidden { display:none !important; }
    .clear { @include clearfix; }
    object { pointer-events:none; display:block; }
    form {
        input, button, textarea { outline:none !important; }
    }
    h1, h2, h3, h4 { color:$colorOrange; }
}

::selection {
    background-color:$colorOrange;
    color:#FFFFFF;
}

::-moz-selection {
    background-color:$colorOrange;
    color:#FFFFFF;
}

.text-right {
    text-align: right;
}

/** Logo - Startseite */
.logo {
    &-wrapper {
        background-color:$colorLightGrey;
        display:inline-block;
        padding:40px 15px 40px 18px;
        position:relative;
        z-index:1;
        margin-top:175px;
        width: 390px;

        h1 {
            color:#FFFFFF;
            margin:45px 0 0 0;
            font-size:3.625em;
            line-height:0.776em;

            span {
                font-size:0.655em;
            }
        }
    }
    &-little {
        max-width:108px;
        float:right;
        margin-top:8px;
        display:none;
    }
}

/** navigation */
.nav-wrapper {
    position:absolute;
    top:0;
    left:0;
    right:0;
    width:100%;
    z-index:100;
    background: transparent;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%);
    @media(min-width: 768px) {
        min-height: 100px;

    }
}

.navbar-default {
    @extend .navbar-default;
    background:transparent;
    border:none;
    margin-bottom:0;

    .navbar-header {
        background-color:rgba(34,38,36,0);
        @include transition(.3s ease background-color);
        &.open {
            background-color:rgba(34,36,38,1);
        }
    }

    .navbar-nav {
        margin-top:20px;
        position:relative;

        li {
            position:relative;
            font-size:1.25em;
            font-weight:bold;
            text-transform:lowercase;
            a:focus {
                color:$colorOrange;
            }
            &.active, &.trail {
                a, span {
                    color:$colorOrange;
                }
            }
            &.follow-me {
                position:absolute;
                bottom:0;
                left:-2px;
                width:88px;
                height:7px;
                pointer-events:none;
                @include transition(transform 0.5s);
                span {
                    position:absolute;
                    background-color:$colorOrange;
                    height:7px;
                    width:75px;
                    bottom:0;
                    right:3px;
                    padding:0;
                    @include transition(width 0.5s ease 0.5s);
                }
            }
            /** folow animation */
            &.trail:nth-child(1) ~ li.follow-me, &.active:nth-child(1) ~ li.follow-me {
                width:75px;
                @include transform(translateX(0.7em));
                span { width:75px; }
            }
            &:nth-child(1):hover ~ li.follow-me {
                width:75px !important;
                @include transform(translateX(0.7em) !important);
                span { width:75px !important; }
            }

            &.trail:nth-child(2) ~ li.follow-me, &.active:nth-child(2) ~ li.follow-me {
                width:100px;
                @include transform(translateX(5em));
                span { width:100px; }
            }
            &:nth-child(2):hover ~ li.follow-me {
                width:100px !important;
                @include transform(translateX(5em) !important);
                span { width:100px !important; }
            }

            &.trail:nth-child(3) ~ li.follow-me, &.active:nth-child(3) ~ li.follow-me {
                width:80px;
                @include transform(translateX(10.7em));
                span { width:80px; }
            }
            &:nth-child(3):hover ~ li.follow-me {
                width:80px !important;
                @include transform(translateX(10.7em) !important);
                span { width:80px !important; }
            }

            &.trail:nth-child(4) ~ li.follow-me, &.active:nth-child(4) ~ li.follow-me {
                width:75px;
                @include transform(translateX(15.5em));
                span { width:75px; }
            }
            &:nth-child(4):hover ~ li.follow-me {
                width:75px !important;
                @include transform(translateX(15.5em) !important);
                span { width:75px !important; }
            }

            &.trail:nth-child(5) ~ li.follow-me, &.active:nth-child(5) ~ li.follow-me {
                width:120px;
                @include transform(translateX(20em));
                span { width:120px; }
            }
            &:nth-child(5):hover ~ li.follow-me {
                width:120px !important;
                @include transform(translateX(20em) !important);
                span { width:120px !important; }
            }

            &.trail:nth-child(6) ~ li.follow-me, &.active:nth-child(6) ~ li.follow-me {
                width:95px;
                @include transform(translateX(26.5em));
                span { width:95px; }
            }
            &:nth-child(6):hover ~ li.follow-me {
                width:95px !important;
                @include transform(translateX(26.5em) !important);
                span { width:95px !important; }
            }
            a, > span {
                color:#FFFFFF;
                display:block;
                line-height:1em;
                letter-spacing:0.06em;
                padding:15px 0 15px 30px;
            }
            a {
                @include transition(color ease .3s);
                &:hover {
                    color:$colorOrange;
                }
            }
        }

        /** dropdown menu */
        .dropdown-menu {
            left:0;
            @include border-radius(0);
            @include box-shadow(none);
            background-color:transparent;
            border:none;
            text-align:left;

            li {
                a:focus, a:hover {
                    background-color:transparent;
                }
            }
        }
    }

    .navbar-toggle:hover,
        .navbar-toggle:active,
        .navbar-toggle:focus {
        background:transparent !important;
    }

    .navbar-toggle {
        border:none;
        width:40px;
        height:40px;
        margin-top:45px;
        margin-right:25px;
        cursor:pointer;
        padding:0;
        @include transform(rotate(0deg));
        @include transition(.5s ease-in-out);

        .iconbar  {
            display: block;
            position: absolute;
            height:4px;
            width:50%;
            background:$colorOrange;
            opacity: 1;
            @include transform(rotate(deg(0deg)));
            @include transition(.25s ease-in-out);

            &:nth-child(even) {
                left: 50%;
                @include border-radius(0 9px 9px 0);
            }

            &:nth-child(odd) {
                left:1px;
                @include border-radius(9px 0 0 9px);
            }

            &:nth-child(1), &:nth-child(2) {
                top:0;
            }

            &:nth-child(3), &:nth-child(4) {
                top:13px;
            }

            &:nth-child(5), &:nth-child(6) {
                top:26px;
            }
        }
    }

    .navbar-toggle[aria-expanded="true"] {
        .iconbar {
            &:nth-child(1), &:nth-child(6) {
                @include transform(rotate(45deg));
            }

            &:nth-child(2), &:nth-child(5) {
                @include transform(rotate(-45deg));
            }

            &:nth-child(1) {
                left:5px;
                top:9px;
            }

            &:nth-child(2) {
                left:calc(50% - 3px);
                top:9px;
            }

            &:nth-child(3) {
                left:-50%;
                opacity:0;
            }

            &:nth-child(4) {
                left:100%;
                opacity:0;
            }

            &:nth-child(5) {
                left:5px;
                top:21px;
            }

            &:nth-child(6) {
                left:calc(50% - 3px);
                top:21px;
            }
        }
    }
}

/** Nav affix */
header {
    .navbar-default {
        text-align:center;

        nav.navbar.affix {
            min-height:50px;
            position: fixed;
            top: -600px;
            right:0;
            left:0;
            width: 100%;
            z-index:9000;
            @include border-radius(0);
            @include box-shadow(0px 3px 5px -2px rgba(0,0,0,.5));
            @include transition(.9s transform ease .5s);
            @include transform(translateY(600px));
            background-color:$colorLightGrey;

            .navbar-toggle {
                margin-top:15px;
                .iconbar {
                    background-color:#FFFFFF;
                }
            }

            .navbar-nav {
                vertical-align:top;
                float:none;
                display:block;
            }

            .logo-little {
                display:block;
            }
        }

        nav.affix.alwaysfixed {
            top:0;
            @include transform(none);

            .logo-little { display:block; }
        }
    }
}

/** parallax */
.parallax {
    background-position:50% 0;
    background-size:cover;
    background-repeat:no-repeat;
    min-height:900px;
    min-height:670px;
    height:100vh;
    background-color:$colorLightGrey;
    position:relative;
    text-align:center;
    overflow: hidden;

    &.sub {
        min-height:487px;
        height: 50vh;
    }

    &.standort {
        min-height:730px;
    }

    .home & {
        max-height: 900px !important;
    }
}

/** ribbon */
.ribbon {
    position:absolute;
    left:50%;
    margin-left:-408px;
    background-image:url('../images/start/ribbon.svg');
    background-repeat:no-repeat;
    width:816px;
    height:900px;
    z-index:0;
}

@media(min-width: 1220px) {
    .home .parallax {
        padding-left: 33%
    }
    .ribbon {
        left: 66%
    }
}

/** startseite - teaser */
.light {
    background-color:$colorLightGrey
}

.dark {
    background-color:$colorDarkGrey;
}

/** content box */
.lightlight {
    background-color:$colorLightLightGrey;
    padding-top:92px;
    padding-bottom:92px;

    > .container {
        position:relative;
        &::before {
            content:"";
            background-color:$colorOrange;
            position:absolute;
            display:block;
            top:11px;
            left:-3016px;
            z-index:1;
            height:79px;
            width:3000px;
        }
    }
    > .container-fluid {
        .row {
            align-items:flex-start;
            -webkit-align-item:flex-start;
        }
    }
}

/** btn */
.btn {
    position:relative;
    font-weight:bold;
    color:$colorOrange;
    display:inline-block;
    text-decoration:none !important;
    @include transition(ease all .3s);

    &::after {
        content:">";
        position:absolute;
        left:-10px;
        opacity:0;
        @include transition(ease opacity .3s);
    }

    &:hover {
        color:$colorOrange;
        &::after {
            opacity:1;
            color:$colorOrange;
        }
    }
}

.btn-full {
    @extend .btn;

    padding:17px 30px;
    color:#FFFFFF !important;
    background-color:$colorLightLightGrey;

    &::after { left:18px; }

    &:hover {
        color:#FFFFFF;;

        &::after {
            color:#FFFFFF;;
        }
    }
}

main {
    .teaser {
        .animation-wrapper {
            min-height:132px;
        }

        h3 {
            font-weight:bold;
            font-size:2.5em;
            line-height:1.125em;
            margin:0 0 33px 0;
            display:inline-block;
            margin-left:18px;
        }

        .grid-4 {
            padding:108px 96px 54px 91px;
            min-height:398px;
            p {
                font-size:0.875em;
                margin:0;
                color:#FFFFFF;
                min-height:96px;
            }

            .btn { float:right; margin-top:25px; }

            &:hover {
                .marker .pin {
                    @include animation(.5s bounce infinite);
                }

                .sized-box {
                    .arrow-to-top {
                        @include animation(sizeme-top 1.5s linear 0s infinite);
                    }

                    .arrow-to-right {
                        @include animation(sizeme-right 1.5s linear 0s infinite);
                    }
                }

                .checked-box {
                    .checker {
                        &::before {
                            @include animation(checker-small 1.5s linear 0s infinite);
                        }

                        &::after {
                            @include animation(checker-large 1.5s linear 0s infinite);
                        }
                    }
                }
            }
        }
    }

    h1, h2 {
        font-size:2.5em;
        font-weight:bold;
        line-height:1.25em;
        margin:0 0 0 3px;
    }

    h4 {
        font-size:1.25em;
        font-weight:normal;
        color:#FFFFFF;
        margin:20px 0 50px 1px;
    }

    .count > p, p {
        color:#FFFFFF;
        font-size:0.875em;
        line-height:1.714em;
        margin:0 0 35px 0;
        span {
            color:$colorOrange;
        }
    }
    .sicherung {
        h4 {
            margin:60px 0 30px 0;
            font-weight:bold;
            font-size:1.125em;
        }
    }

    .full-width {
        background-color:$colorOrange;
        padding:74px 15px;

        h2 {
            font-size:3.75em;
            text-align:center;
            color:#FFFFFF;
            opacity:0;
            @include transform(translateX(-100%));
        }
    }
    /** spezielle Angebote */
    .offers {
        padding:92px 0 92px 0;
        .container::before { display:none; }
        .grid-4 { position:relative; }

        h2 {
            margin:0 0 30px 0;
            color:#FFFFFF;
            font-size:1.125em;
        }

        p { margin:0; }
        span {
            display:block;
            text-align:right;
            color:#FFFFFF;
            position:absolute;
            bottom:-35px;
            right:0;
        }
    }

    .enumeration {
        padding:31px 34px 4px 20px;
        margin-top:59px;

        .custom-topping { position:relative; top:-30px; }

        p {
            position:relative;
            margin: 0 0 20px 0;
            padding-left: 12px;
            &::before {
                content:"";
                position:absolute;
                top:7px;
                top:6px;
                left:-10px;
                left:-8px;
                background-color:$colorOrange;
                height:10px;
                width:6px;

            }
        }
    }
    a { color:$colorOrange !important; }
    span.mail {
        color: #fff;
    }
}


/** preise */
.preise {
    min-height:0;
    height: auto;
    padding: 92px 0;
    overflow:hidden;
    max-height: none !important;

    .btn-full {
        top:55px;
    }

    .container {
        position:relative;
    }

    .ribbon {
        background-image:url('../images/preise/ribbon.svg');
        left:-300px;
        margin:0;
        top:-93px;
        height:677px;
    }
    .preis-subject {
        background-color:$colorLightLightGrey;
        margin-bottom:1px;
        padding:18px 15px;
        p, span {
            color:#fff;
            font-size:1.125em;
            line-height:1em;
        }
        p {
            margin:0;
            font-weight:bold;
            text-align:right;
        }
        span {
            display:block;
            min-height:55px;
            &.preis {
                font-weight:bold;
            }
        }
    }

    .preis-column {
        @include transition(ease transform .3s);
        position:relative;
        margin-bottom: 30px;
        z-index:1;
        &:hover {
            @include transform(scale(1.05));
        }
        &::after {
            content:"";
            position:relative;
            display:block;
            border-right:170px solid transparent;
            border-top:24px solid $colorOrange;
        }
        .highlight {
            background-color:#ca6b00;
            position:absolute;
            display:block;
            width:10px;
            height:100%;
            top:0;
            left:-5px;
            bottom:0;
            z-index:-1;
            @include transform(rotate(1deg));
            &::after {
                content:"";
                position:absolute;
                border-right:40px solid transparent;
                border-top:8px solid #ca6b00;
                bottom:-3px;
                left:0;
            }
        }
        .preis-subject {
            padding:0;
            margin-bottom:0;
            position:relative;
            &::before {
                content:"";
                position:absolute;
                display:block;
                left:11px;
                right:11px;
                bottom:0;
                height:1px;
                background-color:#FFFFFF;
            }
            &.last {
                padding-bottom:25px;
                &::before {
                    visibility:hidden;
                }
            }
            span {
                padding:18px 15px;
            }
        }
        h2 {
            background-color:$colorLightLightGrey;
            color:#FFFFFF;
            font-size:1.5em;
            padding-top:49px;
            padding-bottom:64px;
            line-height:1em;
            margin:0;
        }
    }

    @media(min-width: 992px) {
        &-subjects {
            margin-top:137px;
        }

    }
}


.vorteile {
    padding:92px 0 92px 0;
    h2 {
        font-size:2.5em;
    }
    .vorteile-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .wrap-item {
        flex: 1 0 100%;
        /*display:table;*/
        margin-bottom:29px;
        .table-item {
            display:table-cell;
            vertical-align:middle;
            p, h3 {
                margin:0;
                color:#FFFFFF;
            }
            img {
                margin-right:15px;
                max-width:44px;
                min-width:44px;
            }
        }
    }
    .grid-12 {
        padding: 0 15px;
    }
    @media(min-width: 768px) {
        .wrap-item {
            flex-basis: 50%;
        }
    }
    @media(min-width: 1200px) {
        .wrap-item {
            flex-basis: 33%;
        }
    }
}

#counter {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    .grid-3 {
        // flex: 1 0 auto;
    }
    .counting-text {
        margin-top:20px;
        padding: {
            left: 15px;
            right: 15px;
        }
    }
    @media(min-width: 720px) {
        flex-direction: row;
        .grid-3 {
            flex-basis: 50%;
        }
    }
    @media(min-width: 1200px) {
        .grid-3 {
            flex-basis: calc(25% - 1px);
        }
    }
}


/** Slicker */
#slicker {
    width:100%;
    height:100%;
    div {
        height:100%;
        width:100%;
    }
    .slick-image {
        // cursor:pointer;
        background-position:center center;
        background-size:cover;
    }
}
#slick-dots {
    ul {
        list-style:none;
        display:inline-block;
        position:absolute;
        margin:0 auto;
        padding:0;
        bottom:30px;
        left:50%;
        @include transform(translateX(-50%));

        li {
            position:relative;
            display:block;
            float:left;
            margin:0 6px;
            width:20px;
            height:20px;
            @include transform(rotate(360deg));

            button {
                overflow:hidden;
                background-color:transparent;
                @include box-shadow(inset 0 0 0 2px $colorOrange);
                @include transition(background 0.3s ease);
                top:0;
                left:0;
                width:100%;
                height:100%;
                outline:none;
                @include border-radius(50%);
                text-indent: -999em;
                cursor:pointer;
                position:absolute;
                color:$colorOrange;
                border:none;

                &::after {
                    content:'';
                    position:absolute;
                    bottom:0;
                    height:0;
                    left:0;
                    width:100%;
                    background-color:$colorOrange;
                    @include border-radius(50%);
                    @include box-shadow(0 0 1px $colorOrange);
                    @include transition(height 0.3s ease);
                }
            }

            &.slick-active {
                button {
                    &::after {
                        height:100%;
                    }
                }
            }
        }
    }
}

/** footer */
footer {
    background-size: cover;
    min-height:510px !important;
    padding:85px 0 15px 0;
    text-align:left !important;

    img { margin-bottom:5px; }

    p {
        color:#FFFFFF;
        font-size:0.875em;
        line-height:1.714em;
        margin:0 0 30px 0;

        span { width:35px; display:inline-block; }

        a {
            color:#FFFFFF;
            text-decoration:none !important;
            @include transition(color ease .3s);
            &:hover { color:$colorOrange; }
        }
    }

    .container {
        position:relative;
        .custom-align {
            position:absolute;
            right:0;
            bottom:12px;
        }
    }

    .footer-nav {
        float:right;
        ul {
            list-style-type:none;
            li {
                font-size:0.875em;
                line-height:1.714em;
                text-transform:lowercase;

                &.active, &.trail {
                    a, span {
                        color:$colorOrange;
                    }
                }
            }

            a, span { color:#FFFFFF; }
            a {
                text-decoration:none !important;
                @include transition(color ease .3s);
                &:hover { color:$colorOrange; }
            }
        }
    }

    .lowercase { text-transform:lowercase; }
}

.sub-footer {
    padding:7px 0 10px 0;
    text-align:center;
    a {
        color:#FFFFFF !important;
        font-size:0.875em;
        line-height:1.714em;
    }
}

/** cookies */
#cookie-hint {
    position:fixed;
    bottom:-200px;
    left:0;
    right:0;
    z-index:10000;
    background-color:rgba(25, 26, 28, .9);
    text-align:center;
    color:#fff;
    padding: 12px;
    p {
        margin:0;
        span {
            display:block;
        }
    }
    a {
        white-space:normal;
        background:#fff;
        color:$colorOrange;;
        cursor:pointer;
        display:inline-block;
        font-weight:bold;
        margin-right:5px;
        margin-top:15px;
        padding:5px 10px;
        text-decoration:none;
        vertical-align:middle;
        width:auto;
        border:0;
    }
}

.need-svg,
.need-js {
    display: none;
}

html {
    &.js {
        .need-js {
            display: block;
        }
    }
    &.svg {
        form label.input-effect {
            margin-bottom: 35px;
        }
        form label span.error {
            display: block;
        }
        form label > span,
        .svg-fallback {
            display: none
        }
        .need-svg {
            display: block;
        }
    }
}

/** form */
form {
    .btn-full {
        border:none;
        background-color:transparent;
        float:right;
        background-color:$colorOrange;
        color:#FFFFFF !important;

        &:after {
            top: 17px;
        }

        &:hover::after {
            color:#FFFFFF;
        }
    }

    label {
        color:#fff;

        &.checkbox {
            float: left;
        }
    }

    p.error {
        color: #ff1919;
    }
    span.error {
        color: #ff1919;
        font-size: 14px;
    }
    .checkbox {
        span.error {
            margin-top: 0;
        }
    }

    input::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
    }

    .input-effect {
        margin-bottom:13px;
        display: block;
        position:relative;

        &:before {
            content: '';
            position: absolute;
            width: 3px;
            height: 100%;
            background: $colorOrange;
            left: 0;
            top: 0;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        &.focus {
            &:before {
                opacity: 1;
            }
        }

        input, textarea {
            width: 100%;
            display: block;
            border: none;
            padding: 7px 14px;
            @include transition(ease all .4s);
            color: #222426;

            &.error {
                border: 1px solid #ff1919;
            }


            // &:focus, &.has-content {
            //     ~ label {
            //         top:-25px;
            //         left:0;
            //         font-weight:bold;
            //         color:$colorOrange;
            //     }

            //     ~ .focus-border {
            //         &::before, &::after {
            //             left:0;
            //             width:100%;
            //         }

            //         i {
            //             &::before, &::after {
            //                 top:0;
            //                 height:100%;
            //                 @include transition(ease all .6s);
            //             }
            //         }
            //     }
            // }
        }

        textarea { min-height:250px; }

        // label {
        //     color:#fff;
        //     position:absolute;
        //     left:14px;
        //     width:100%;
        //     top:10px;
        //     top: 7px;
        //     @include transition(ease all .3s);
        //     letter-spacing:0.1em;
        //     cursor:text;
        // }

        // .focus-border {
        //     &::before, &::after {
        //         content:"";
        //         position:absolute;
        //         top:-1px;
        //         left:50%;
        //         width:0;
        //         height:3px;
        //         background-color:$colorOrange;
        //         @include transition(ease all .4s);
        //     }

        //     &::after {
        //         top:auto;
        //         bottom:0;
        //     }

        //     i {
        //         &::before, &::after {
        //             content:"";
        //             position:absolute;
        //             top:50%;
        //             left:-1px;
        //             width:3px;
        //             height:0;
        //             background-color:$colorOrange;
        //             @include transition(ease all .6s);
        //         }

        //         &::after {
        //             left:auto;
        //             right:0;
        //         }
        //     }
        // }
    }

    .tooltip-inner { background-color:$colorOrange; }
    .tooltip.top .tooltip-arrow { border-top-color:$colorOrange; }
}

/** downloads */
.downloads {
    list-style:none;
    padding-left:0;

    li {
        font-weight:normal;
        margin-bottom:25px;

        p {
            margin:0;
            line-height:1.2em;

            span { color:#FFFFFF; }
        }

        > span {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display:         flex;
            @extend .flex-direction;
            @extend .justify-content;
        }

        a {
            color:$colorOrange !important;
            text-decoration:none;

            &:hover { text-decoration:underline; }
        }

        img { display:block; position:relative; max-width:44px; min-width:44px; margin-right:15px; }
    }
}

.last-p-tag { margin-bottom:0; }

/** media queries */
@media only screen and (max-width:1700px) {
    main {
        .teaser {
            .grid-4 {
                h3 {
                    font-size:1.8em;
                }
            }
        }
    }
}

@media only screen and (max-width:1459px) {
    main {
        .teaser {
            .grid-4 {
                padding:50px;
                min-height:auto;
            }
        }
    }
}

@media only screen and (max-width:1199px) {
    main {
        .preise {
            .preis-column {
                &::after {
                    border-right:125px solid transparent;
                    border-top:15px solid #ec6b10;
                }
            }

            /*.grid-offset-1 { width:33.3333333333%; margin-left:0; }*/
        }
        .grid-offset-2 { margin-left:16.6666666667% }
        .teaser {
            .grid-4 {
                padding:50px 25px;
                h3 { margin-left:3px; }
            }
        }
        .sicherung h4 { margin:30px 0 15px 0; }
        .enumeration {
            padding:50px 34px 0px 20px;
            .custom-topping {
                top:-55px;
            }
        }
    }
}

@media only screen and (max-width:991px) {
    .logo-wrapper {
        padding:40px 35px 40px 18px;
        h1 {
            font-size:2.5em;
        }
    }

    .parallax { background-position:50% 50% !important; }
    .ribbon { display:none !important; }

    .row-eq-height { display:block; }
    .grid-4 { width:100%; }
    .grid-offset-1 { margin-left:0; }

    main {
        h1, h2, h3 { font-size:2em !important; }
        .vorteile {
            h3 {
                font-size:1.1em !important;
            }
        }

        .preise {
            .btn-full { top:0; margin-top:35px; }

            .preise-subjects { display:none; }
            .grid-2 {
                width:100%;
                margin-bottom:35px;
            }

            .preis-column {
                padding:0 15px;

                .highlight {
                    left:9px;

                    &::after {
                        display:none;
                    }
                }

                .preis-subject {
                    span {
                        &::before {
                            content:attr(data-column);
                            color:#FFFFFF;
                            margin-right:15px;
                        }
                    }
                }

                &::after {
                    border:none;
                    height:6px;
                    background-color:#ca6b00;
                }
            }
        }

        .offers {
            padding-top:27px;
            h2 { margin-top:30px; }
            span { position:relative; margin-bottom:15px; }
        }

        .teaser {
            .animation-wrapper {
                min-height:auto;
                h3 { margin-bottom:1em; }
            }

            .grid-4 {
                padding:25px 133px 25px 131px;
                p { min-height:auto; }

            }
        }

        .enumeration { margin-bottom:60px; }

        .vorteile {
            .wrap-item {
                /*                width:50%;
                                float:left;
                                display:block;*/
            }
        }

        .enumeration {
            padding:31px 34px 34px 20px;
            .custom-topping { top:auto; }
        }

        &.startpage {
            .grid-6 {
                width:100%;
            }
        }
    }

    footer {
        img { margin-bottom:35px; }
    }

    .custom-container {
        .container-fluid {
            .container { float:none; max-width:750px; width:750px; padding:0 15px; }
            .grid-6 { width:100%; }
        }
    }

    header .navbar-default nav.navbar.affix .logo-little{ display:none; }
    .last-p-tag { margin-bottom:35px; }
    #slicker {
        div {
            height:350px;
        }
    }
}

@media only screen and (max-width:767px) {
    .home .parallax {
        background-image: url(../images/header.jpg) !important;
    }
    header .navbar-default nav.navbar.affix .logo-little{
        display:block;
        float:none;
        margin:15px auto 0 auto;
    }
    header .navbar-collapse {
        -webkit-box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 3px 5px -2px rgba(0, 0, 0, 0.5);
        -webkit-transition: 0.9s transform ease 0.5s;
    }
    .nav-wrapper {
        padding:0;
    }
    .navbar-default {
        .navbar-collapse {
            background-color:$colorLightGrey;
        }
        .navbar-nav {
            li {
                a, span {
                    padding:15px;
                }
                &.follow-me {
                    display:none;
                }
                &.active {
                    span {
                        display:inline-block;
                        position:relative;
                        &::after {
                            content:"";
                            display:block;
                            width:100%;
                            background-color:$colorOrange;
                            height:7px;
                            position:absolute;
                            bottom:0;
                            left:0;
                            right:0;
                        }
                    }
                }
            }
        }
    }

    .custom-container {
        .container-fluid {
            .container { max-width:100%; width:100%; padding:0; }
        }
    }

    main {
        .teaser {
            .grid-4 {
                padding:25px 15px 25px 15px;
                h3 {
                    margin-left:10px;
                }
            }
        }
        .grid-offset-2 {
            margin-left:0;
        }
    }
    .parallax {
        max-height:550px;
        min-height:550px;
        .logo-wrapper {
            margin-top:100px;
        }
    }
    .parallax.standort {
        min-height:250px;
    }
    footer {
        img {
            max-width:250px;
        }
    }
    .fakten-wrapper {
        max-width:350px;
        margin:0 auto;
    }
    #slicker {
        div {
            height:250px;
        }
    }
}

@media only screen and (max-width:550px) {
    main {
        .teaser {
            .grid-4 {
                h3 {
                    margin-left:0;
                    display:block;
                }
            }
        }
    }
}

@media only screen and (min-width:992px) {
    .custom-container {
        .container-fluid {
            padding-right:0;
            .container {
                float:right;
                max-width:462px;
                width:100%;
                padding:0;
            }
            .custom-child {
                padding-right:0;
            }
        }
    }

    main {
        margin-bottom:550px;
        margin-bottom:580px;
    }
    .footer-wrapper {
        position:fixed;
        left:0;
        right:0;
        bottom:0;
        z-index:-1;
        /*padding-top: 30px;*/
        width:100%;
    }
    footer {
        padding-top: 30px
    }
}

@media only screen and (min-width:1200px) {
    .custom-container {
        .container-fluid {
            .container { max-width:593px; }
        }
    }
}