/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 30.01.2018, 14:00:00
    Author     : Linku
                 Maik Lampe <ml@easy-media.de>
*/

/** border animation */
.border-container {
    position:relative;
    width: 305px;
    height: 172px;
    h1, h2, p {
        position:absolute;
        text-align:left;
        font-size:4.5em;
        font-weight:bold;
        color:$colorOrange;
        margin:0;
        right:-42px;
        bottom:-4px;
        line-height:0.8em;
        font-family: Chivo, sans-serif;
    }

    .border {
        position:absolute;
        left:0;
        top:0;
        right:0;
        bottom:0;

        &::before, &::after {
            content:"";
            position:absolute;
            background-color:$colorOrange;
        }

        &.border-bottom-left {
            &::before {
                height:9px;
                width:0;
                right:195px;
                bottom:0;
            }

            &::after {
                width:9px;
                height:0;
                left:0;
                bottom:0;
            }
        }

        &.border-top-right {
            &::before {
                height:9px;
                width:0;
                left:0;
                top:0;
            }

            &::after {
                width:9px;
                height:0;
                right:0;
                top:0;
            }
        }
    }

    .square {
        width: 10px;
        height: 8px;
        background-color: #222426;
        position: absolute;
        right: 0;
        bottom: 38px;
    }

    &.start {
        .border-bottom-left {
            &::before {
                width:110px;
                @include transition(width 0.25s ease-out 0.5s);
            }

            &::after {
                height:172px;
                @include transition(height 0.25s ease-out 0.75s);
            }
        }

        .border-top-right {
            &::before {
                width:305px;
                @include transition(width 0.25s ease-out 1s);
            }

            &::after {
                // height:128px;
                height: 173px;
                @include transition(height 0.25s ease-out 1.25s);
            }
        }
    }
}

.chrome {
    .border-container {
        h2 { right:-42px; }
    }
}

.sub {
    padding-left: 0;
    .border-container {
        display:block;
        margin: 155px auto 0 auto;
        width:350px;

        &.start {
            .border-bottom-left {
                &::before {
                    width:155px;
                }
            }

            .border-top-right {
                &::before {
                    width:350px;
                }
            }
        }

        h1 {
            font-family:'Gudea',sans-serif,Helvetica Neue,Helvetica,Arial;
            font-size:3.6em;
            text-transform:lowercase;
            right:auto;
            bottom:auto;
            left:50%;
            top:50%;
            @include transform-origin(0 0);
            @include transform(translate(-50%,-50%));
        }
    }
}

/** counter - kleiner Border und verzögerte Animationen*/
.count {
    position:relative;
    p {
        color:#fff;
        font-size:1.8em;
        line-height:1.25em;
        text-align:center;
        margin:0;
        bottom:auto;
        right:auto;

        span {
            color:#fff;
        }
    }
    .border-container {
        margin: auto;
        max-width:275px;
        p {
            font-family:'Gudea',sans-serif,Helvetica Neue,Helvetica,Arial;
            position:absolute;
            left:50%;
            top:50%;
            white-space: nowrap;
            @include transform(translate(-50%, -50%));
            @include transform-origin(0 0);
        }

        .border {
            &.border-bottom-left {
                &::before {
                    height:9px;
                    width:0;
                    right:184px;
                    bottom:0;
                }

                &::after {
                    width:9px;
                    height:0;
                    left:0;
                    bottom:9px;
                }
            }

            &.border-top-right {
                &::before {
                    height:9px;
                    width:0;
                    left:0;
                    top:0;
                }

                &::after {
                    width:9px;
                    height:0;
                    right:0;
                    top:0;
                }
            }
        }

        &.start {
            .border-bottom-left {
                &::before {
                    width:91px;
                }

                &::after {
                    height:154px;
                }
            }

            .border-top-right {
                &::before {
                    width:275px;
                }

                &::after {
                    height:99px;
                }
            }
        }
    }

    &#quadratmeter {

        .border-container {
            .border {
                &.border-bottom-left {
                    &::before {
                        @include transition(width 0.5s ease-out);
                    }

                    &::after {
                        @include transition(height 0.5s ease-out 0.5s);
                    }
                }

                &.border-top-right {
                    &::before {
                        @include transition(width 0.5s ease-out 1s);
                    }

                    &::after {
                        @include transition(height 0.5s ease-out 1.5s);
                    }
                }
            }
        }
    }

    &#tag {

        .border-container {
            .border {
                &.border-bottom-left {
                    &::before {
                        @include transition(width 0.75s ease-out);
                    }

                    &::after {
                        @include transition(height 0.75s ease-out 0.75s);
                    }
                }

                &.border-top-right {
                    &::before {
                        @include transition(width 0.75s ease-out 1.5s);
                    }

                    &::after {
                        @include transition(height 0.75s ease-out 2.25s);
                    }
                }
            }
        }
    }

    &#kontakte {

        .border-container {
            .border {
                &.border-bottom-left {
                    &::before {
                        @include transition(width 1s ease-out);
                    }

                    &::after {
                        @include transition(height 1s ease-out 1s);
                    }
                }

                &.border-top-right {
                    &::before {
                        @include transition(width 1s ease-out 2s);
                    }

                    &::after {
                        @include transition(height 1s ease-out 3s);
                    }
                }
            }
        }
    }

    &#leds {

        .border-container {
            .border {
                &.border-bottom-left {
                    &::before {
                        @include transition(width 1.25s ease-out);
                    }

                    &::after {
                        @include transition(height 1.25s ease-out 1.25s);
                    }
                }

                &.border-top-right {
                    &::before {
                        @include transition(width 1.25s ease-out 2.5s);
                    }

                    &::after {
                        @include transition(height 1.25s ease-out 3.75s);
                    }
                }
            }
        }
    }
}

/** marker animation */
.marker {
    display:inline-block;
    .pin {
        background-image:url('../images/custom_icos/marker/pin.svg');
        background-repeat:no-repeat;
        height:68px;
        width:48px;
        position:relative;
        left:13px;
        top:10px;
    }

    .ring {
        background-image:url('../images/custom_icos/marker/ring.svg');
        height:22px;
        width:73px;
    }
}

@-webkit-keyframes bounce {
    0%, 100% {
        @include transform(translateY(0));
    }
    50% {
        @include transform(translateY(-5px));
    }
}
@-moz-keyframes bounce {
    0%, 100% {
        @include transform(translateY(0));
    }
    50% {
        @include transform(translateY(-5px));
    }
}
@-o-keyframes bounce {
    0%, 100% {
        @include transform(translateY(0));
    }
    50% {
        @include transform(translateY(-5px));
    }
}
@keyframes bounce {
    0%, 100% {
        @include transform(translateY(0));
    }
    50% {
        @include transform(translateY(-5px));
    }
}

/** sized box animation */
.sized-box {
    display:inline-block;
    width:78px;
    height:74px;
    border:solid 4px $colorOrange;
    position:relative;

    .arrow-to-top, .arrow-to-right {
        position:absolute;
        background-color:$colorOrange;

        &::after {
            content:"";
            position:relative;
            display:block;
            width:0;
            height:0;
        }
    }

    .arrow-to-top {
        width:6px;
        left:10px;
        bottom:10px;
        height:25px;

        &::after {
            border-left:8px solid transparent;
            border-right:8px solid transparent;
            border-bottom:8px solid $colorOrange;
            left:-5px;
            top:-8px;
        }
    }

    .arrow-to-right {
        height:6px;
        left:11px;
        bottom:9px;
        width:25px;
        @include scale(-1);

        &::after {
            border-top:8px solid transparent;
            border-bottom:8px solid transparent;
            border-right:8px solid $colorOrange;
            right:8px;
            top:-5px;
        }
    }
}

@-webkit-keyframes sizeme-top {
    0% {
        height:0;
    }
    50% {
        height:25px;
    }
}

@keyframes sizeme-top {
    0% {
        height:0;
    }
    50% {
        height:25px;
    }
}

@-o-keyframes sizeme-top {
    0% {
        height:0;
    }
    50% {
        height:25px;
    }
}

@-moz-keyframes sizeme-top {
    0% {
        height:0;
    }
    50% {
        height:25px;
    }
}

@-webkit-keyframes sizeme-right {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@keyframes sizeme-right {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@-o-keyframes sizeme-right {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@-moz-keyframes sizeme-right {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

/** check animation */
.checked-box {
    display:inline-block;
    width:78px;
    height:74px;
    position:relative;

    .right-bottom, .left-top, .checker {
        position:absolute;
        width:78px;
        height:74px;
        left:0;
        top:0;
        right:0;
        bottom:0;
    }

    .right-bottom, .left-top, .checker {
        &::before, &::after {
            content:"";
            background-color:$colorOrange;
            position:absolute;
        }
    }

    .right-bottom {

        &::before {
            right:0;
            top:15px;
            width:4px;
            height:59px;
        }

        &::after {
            left:0;
            bottom:0;
            height:4px;
            width:78px;
        }
    }

    .left-top {
        &::before {
            left:0;
            bottom:0;
            width:4px;
            height:74px;
        }

        &::after {
            left:0;
            top:0;
            height:4px;
            width:60px;
        }
    }

    .checker {
        position:relative;

        &::before, &::after {
            @include transform-origin(0 50%);
        }

        &::before {
            width:25px;
            height:5px;
            left:15px;
            top:25px;
            @include transform(rotate(45deg));
        }

        &::after {
            width:65px;
            height:5px;
            left:30px;
            top:43px;
            @include transform(rotate(315deg));
        }
    }
}

@-webkit-keyframes checker-small {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@keyframes checker-small {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@-o-keyframes checker-small {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@-moz-keyframes checker-small {
    0% {
        width:0;
    }
    50% {
        width:25px;
    }
}

@-webkit-keyframes checker-large {
    0% {
        visibility:hidden;
        width:0;
    }
    50% {
        width:0;
    }
    70% {
        visibility:visible;
        width:65px;
    }
}

@keyframes checker-large {
    0% {
        visibility:hidden;
        width:0;
    }
    50% {
        width:0;
    }
    70% {
        visibility:visible;
        width:65px;
    }
}

@-o-keyframes checker-large {
    0% {
        visibility:hidden;
        width:0;
    }
    50% {
        width:0;
    }
    70% {
        visibility:visible;
        width:65px;
    }
}

@-moz-keyframes checker-large {
    0% {
        visibility:hidden;
        width:0;
    }
    50% {
        width:0;
    }
    70% {
        visibility:visible;
        width:65px;
    }
}

/** fakten - banner */
.full-width.trigger {
    h2 {
        opacity:1;
        @include transform(translateX(0));
        @include transition(ease-out transform 1s);
    }
}

@media only screen and (max-width:1199px) {
    .count {
        .border-container {
            width:220px;
            max-width:220px;
            margin-left:auto;
            margin-right:auto;

            p {
                font-size:1.7em;
            }

            .border {
                &.border-bottom-left {
                    &::before {
                        right:153px;
                    }
                }
            }

            &.start {
                .border-bottom-left {
                    &::before {
                        width:67px;
                    }
                }

                .border-top-right {
                    &::before {
                        width:220px;
                    }

                    &::after {
                        height:138px;
                    }
                }
            }
        }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .border-container.start .border-top-right::after {
        height: 172px !important;
   }
}

@-moz-document url-prefix() {
    .border-container.start .border-top-right::after {
        height: 172px;
    }

    @media only screen and (max-width:991px) {
       .border-container.start .border-top-right::after {
            height: 173px !important;
        }
    }
}

@media only screen and (max-width:991px) {
    .border-container {
        width:220px;
        margin-left:auto;
        margin-right:auto;

        h1, h2, p {
            font-size:3.5em;
            right:-33px;
            right:-32px;
        }

        .border {
            &.border-bottom-left {
                &::before {
                    right:153px;
                }
            }
        }

        &.start {
            .border-bottom-left {
                &::before {
                    height: 7px;
                    width:67px;
                }
                &::after {
                    width: 7px;
                }
            }

            .border-top-right {
                &::before {
                    width:218px;
                    height: 7px;
                }

                &::after {
                    width: 7px;
                    height:174px;
                }
            }

            .square {
                bottom: 29px;
            }
        }
    }

    _:-ms-fullscreen, .border-container.start .border-top-right::after {
        height: 173px !important;
    }

    .chrome .border-container h2 { right:-32px;  }

    #counter {
        /*        .grid-3 { width:48%; }
                .grid-3:nth-child(2n) {
                    &::after {
                        content: ".";
                        visibility: hidden;
                        display: block;
                        clear: both;
                    }
                }*/

        .border-container {
            p {
                right:auto;
            }
        }
    }

    .sub {
        .border-container {
            width:350px;

            &.start {
                .border-bottom-left {
                    &::before {
                        width:197px;
                    }
                }

                .border-top-right {
                    &::before {
                        width:350px;
                    }
                }
            }
        }
    }

    .sub {
        &.parallax {
            min-height:300px !important;
            max-height:300px;
            .border-container {
                margin-top:96px;
            }
        }
    }
}

@media only screen and (max-width:767px) {
    .sub {
        .border-container {
            width: 270px;
            h1 {
                font-size:2.6em;
            }
            &.start {
                .border-bottom-left {
                    &::before {
                        width:117px;
                    }
                }
                .border-top-right {
                    &::before {
                        width:270px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:490px) {
    /*    #counter {
            .grid-3 {
                width:100%;
                &::after {
                    content: ".";
                    visibility: hidden;
                    display: block;
                    clear: both;
                }
            }
        }*/
}