/*
    Created on : 03.02.2015, 08:44:48
    Description: Some SASS mixins
*/

@mixin transform($transform) {
    -moz-transform: $transform;
    -webkit-transform: $transform;
    -o-transform: $transform;
    -ms-transform: $transform;
    transform: $transform;
}

@mixin transition($trans...) {
    -moz-transition: $trans;
    -webkit-transition: $trans;
    -o-transition: $trans;
    -ms-transition: $trans;
    transition: $trans;
}

@mixin transition-delay($trans-delay...) {
    -moz-transition-delay: $trans-delay;
    -webkit-transition-delay: $trans-delay;
    -o-transition-delay: $trans-delay;
    -ms-transition-delay: $trans-delay;
    transition-delay: $trans-delay;
}

@mixin transition-timing-function($trans-func) {
    -moz-transition-timing-function:$trans-func;
    -webkit-transition-timing-function: $trans-func;
    -o-transition-timing-function: $trans-func;
    -ms-transition-timing-function: $trans-func;
    transition-timing-function: $trans-func;
}

@mixin font-size($sizeValue: $defaultFontSize, $lineHeight: $defaultLineHeight) {
    font-size: $sizeValue + px;
    font-size: rempharize($sizeValue);

    $lineMulti: ceil($sizeValue / $lineHeight) * $lineHeight;
    line-height: $lineMulti + px;
    line-height: rempharize($lineMulti);
}

@mixin font-size-withMargin($sizeValue: $defaultFontSize, $lineHeight: $defaultLineHeight) {
    @include font-size($sizeValue,$lineHeight);
    margin: {
        /*top: $lineHeight + px;*/
        /*top: rempharize($lineHeight);*/
        bottom: $lineHeight + px;
        bottom: rempharize($lineHeight);
    }
}

@function rempharize($doubleValue: $defaultFontSize) {
    @return $doubleValue / 16 * 1rem; 
}

@mixin color-generator($name: 'white', $value: '#fff') {
    .fg-#{$name} {
        color: $value;
    }
    .bg-#{$name} {
        background-color: $value;
    }
}

@mixin clearfix {
    zoom:1;
    &::after,
    &::before {
        content: "";
        display: table;
    }    
    &::after {
        clear: both;
    }
}

@mixin border-radius ($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius:    $radius;
  border-radius:         $radius;

  -moz-background-clip:    padding;
  -webkit-background-clip: padding-box;
  background-clip:         padding-box;
}

@mixin box-shadow($shadow...) {
  -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
          box-shadow: $shadow;
}

@mixin animation($anim) {
    -webkit-animation: $anim;
    -moz-animation:    $anim;
    -ms-animation:     $anim;
}

@mixin scale ($scale) {
    -webkit-transform: $scale;
    -moz-transform:    $scale;
    -ms-transform:     $scale;
    -o-transform:      $scale;
}

@mixin rotate ($rotate) {
    -webkit-transform: $rotate;
    -moz-transform:    $rotate;
    -ms-transform:     $rotate;
    -o-transform:      $rotate;
}

@mixin skew ($skew) {
    -webkit-transform: $skew;
    -moz-transform:    $skew;
    -ms-transform:     $skew;
    -o-transform:      $skew;
}

@mixin perspective ($perspective) {
    -webkit-perspective:  $perspective;
    -moz-perspective:   $perspective;
    -ms-perspective:  $perspective;
    perspective:   $perspective;
}

@mixin transform-origin ($x, $y) {
    -webkit-transform-origin: $x, $y;
    -moz-transform-origin:    $x, $y;
    -ms-transform-origin:     $x, $y;
    -o-transform-origin:      $x, $y;
}

@mixin hyphens() {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}